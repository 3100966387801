
import { defineComponent, nextTick, onMounted, ref, Ref } from "vue";

import anime from "animejs";

// @ts-ignore
import blink from "node_modules/blink-detection/index.js";
import ErrorService from "@/ErrorService";

export interface GameLogicInterface {
  /** Показать начальный экран игры (с кнопкой старт) */
  showGame(): void;

  /** Другой пользователь нажал на кнопку старт */
  startGame(): void;

  /** Другой пользователь моргнул */
  // eslint-disable-next-line no-unused-vars
  receivedOtherBlinked(v: { myCount: Number }): void;

  /** Нужно закончить игру (другой пользователь отключился, ...) */
  stopGame(): Promise<void>;
}

// 1 моргание = 100 мс. Проверяем на моргание дважды,
// на случай если кто-то моргнул быстро
// Источник: https://www.somatechnology.com/blog/thursday-thoughts/fast-average-blink/
const BLINK_CHECK_TIMEOUT = 50;

// Подождать пока идет запрос к другому пользователю
const NETWORK_LAG = 50;

// Время между раундами
const ROUND_TIME = 500;

const DIV_ANIMATION_MS = 1000;

export default defineComponent({
  emits: [
    /** Emitted каждый раз после того, как загрузилось все необходимое для игры */
    "loaded",

    /** Emitted после того, как была нажата кнопка начать игру */
    "started",

    /** Emitted один раз если пользователь моргнул
     * Параметры:
     * 1 - { myCount: Number } - счетчик игры
     */
    "user-blinked",

    /**
     * Игра была активна, но она закончилась
     */
    "game-ended",
  ],
  setup(props, context) {
    const startPopupShowing = ref(false);
    const gameStarted = ref(false);

    // показан текст 'round X'
    const roundTextDivShown = ref(false);
    const roundTextDiv: Ref<null | HTMLDivElement> = ref(null);

    const timerDivShown = ref(false);
    const timerDiv: Ref<null | HTMLDivElement> = ref(null);

    const youBlinkedDivShown = ref(false);
    const theyBlinkedDivShown = ref(false);

    const theyWonDivShown = ref(false);
    const youWonDivShown = ref(false);

    const videoElement: Ref<HTMLVideoElement | null> = ref(null);

    // идет ли сейчас раунд
    let gameOn = false;
    let myBlinkCount = 0;
    let otherBlinkCount = 0;

    onMounted(async () => {
      console.log("mounted");
      if (!videoElement.value) {
        ErrorService.error("video element not mounted in game logic");
      }

      await blink.loadModel({
        modelUrl: "/tf/facemesh/model.json",
        irisModelUrl: "/tf/iris/model.json",
        detectorModelUrl: "/tf/blazeface/model.json",
      });

      await blink.setUpCamera(videoElement.value);

      context.emit("loaded");
    });

    const timerAnimation = async () => {
      console.log("timer animation");

      timerDivShown.value = true;
      await nextTick();
      for (var i = 3; i >= 0; i--) {
        if (timerDiv.value == null) {
          continue;
        }

        let startFontSize = "100%";
        let endFontSize = "150%";
        if (i === 0) {
          timerDiv.value.innerHTML = "don't<br>blink!";
          startFontSize = "40%";
          endFontSize = "100%";
        } else {
          timerDiv.value.innerHTML = String(i);
        }

        timerDiv.value.style.fontSize = startFontSize;

        await new Promise((resolve) => {
          anime({
            targets: timerDiv.value,
            // анимация должна быть в общей сложности 1 секунду
            duration: 600,
            endDelay: 400,
            fontSize: endFontSize,
            complete: function () {
              resolve(100);
            },
            easing: "easeOutCubic",
          });
        });
      }

      await nextTick();
      if (timerDiv.value) {
        timerDiv.value.innerHTML = "";
      }

      timerDivShown.value = false;
    };

    const roundNAnimation = async (n: number) => {
      console.log("round n animation");
      roundTextDivShown.value = true;
      await nextTick();

      const fullText = "round " + n;
      var roundObj = {
        counter: 0,
      };

      await new Promise((resolve) => {
        anime({
          targets: roundObj,
          counter: fullText.length,
          round: 1,
          duration: 600,
          endDelay: 1400,
          easing: "linear",
          update: function () {
            if (!roundTextDiv.value) {
              return;
            }

            roundTextDiv.value.innerHTML = fullText.slice(0, roundObj.counter);
          },
          complete: function () {
            resolve(100);
          },
        });
      });
      await nextTick();
      roundTextDivShown.value = false;
      await nextTick();
    };

    const divAnimation = async (b: Ref<boolean>) => {
      console.log("show div animation");

      b.value = true;

      await nextTick();

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(100);
        }, DIV_ANIMATION_MS);
      });

      await nextTick();
      b.value = false;

      console.log("   div animation done");
    };

    const gameLogic = async () => {
      for (var i of [1, 2, 3]) {
        console.log("game logic called");

        await roundNAnimation(i);

        console.log("timer animation called");
        await timerAnimation();

        gameOn = true;

        while (gameOn) {
          await new Promise((resolve) => {
            setTimeout(async () => {
              if (!gameOn) {
                return;
              }

              let result = await blink.getBlinkPrediction();

              if (result.blink || result.longBlink || result.wink) {
                console.log("blink detected");
                gameOn = false;
                myBlinkCount += 1;
                context.emit("user-blinked", { myCount: myBlinkCount });

                await new Promise((resolve) =>
                  setTimeout(() => resolve(100), NETWORK_LAG)
                );

                await divAnimation(youBlinkedDivShown);
              }

              resolve(100);
            }, BLINK_CHECK_TIMEOUT);
          });
        }

        await new Promise((resolve) =>
          setTimeout(() => resolve(100), ROUND_TIME)
        );
      }

      gameOn = false;

      console.log("game done");

      if (myBlinkCount > otherBlinkCount || myBlinkCount == otherBlinkCount) {
        console.log("show mine");
        divAnimation(youWonDivShown);
      }

      if (myBlinkCount < otherBlinkCount || myBlinkCount == otherBlinkCount) {
        console.log("show other");
        divAnimation(theyWonDivShown);
      }

      await new Promise((resolve) => {
        // +50 мс на всякий случай
        setTimeout(() => resolve(100), DIV_ANIMATION_MS + 50);
      });

      context.emit("game-ended");
    };

    const startBtnClicked = async () => {
      console.log("start btn clicked. started: ", gameStarted.value);
      if (gameStarted.value) {
        return;
      }

      gameStarted.value = true;

      context.emit("started");

      /** Подождать 100 мс пока идет запрос к другому пользователю */
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(100);
        }, NETWORK_LAG);
      });

      console.log("startBtnClicked");
      startPopupShowing.value = false;
      await nextTick();
      console.log("game logic");
      await gameLogic();
    };

    // из interface
    const showGame = async () => {
      console.log("show game");
      startPopupShowing.value = true;
    };

    const startGame = () => {
      console.log("start game");
      startBtnClicked();
    };

    const receivedOtherBlinked = (v: { myCount: number }) => {
      otherBlinkCount = v.myCount;
      console.log("winked. other count: ", otherBlinkCount);
      gameOn = false;
      divAnimation(theyBlinkedDivShown);
    };

    const stopGame = async () => {
      gameOn = false;
      myBlinkCount = 0;
      otherBlinkCount = 0;
      await blink.stopPrediction();
    };

    return {
      startPopupShowing,
      timerDiv,
      timerDivShown,
      roundTextDiv,
      roundTextDivShown,
      youBlinkedDivShown,
      theyBlinkedDivShown,
      theyWonDivShown,
      youWonDivShown,
      videoElement,
      startBtnClicked,
      showGame,
      startGame,
      receivedOtherBlinked,
      stopGame,
    };
  },
});
