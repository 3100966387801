
/**
 * Компонент игры гляделок.
 * Включает в себя кнопки, сообщения хочешь поиграть, ...
 */

import { defineAsyncComponent, defineComponent, ref, Ref } from "vue";

import GameOfferStaringContest from "./GameOfferStaringContest.vue";

import GameLoaderMe from "./GameLoaderMe.vue";
import GameLoaderOther from "./GameLoaderOther.vue";

const GameLogic = defineAsyncComponent(
  () => import(/* webpackChunkName: "GameLogic" */ "./GameLogic.vue")
);

import { GameLogicInterface } from "./GameLogic.vue";

import { GamesInterface as AppGamesInterface } from "@/plugins/App";

export interface GamesInterface extends AppGamesInterface {}

const OFFER_DECLINED_TIMEOUT = 2000;

export default defineComponent({
  components: {
    GameOfferStaringContest,
    GameLogic,
    GameLoaderOther,
    GameLoaderMe,
  },

  emits: [
    /**
     * Пользователь ответил на приглашение
     * Параметры:
     * 1 - wants { boolean }. true, если хочет; false - если нет
     */
    "offer-answered",

    /**
     * Моя игра была загружена
     */
    "game-loaded",

    /**
     * Была нажата кнопка начать игру
     */
    "game-started",

    /**
     * Пользователь в игре и моргнул
     */
    "user-blinked",

    /** Игра была закончена */
    "game-ended",
  ],
  setup(props, context) {
    // показывается ли offer поиграть в гляделки
    const offerShowing = ref(false);

    // показывается ли сообщение что пользователь не хочет играть в гляделки
    const offerDeclinedShowing = ref(false);

    // нужно ли показывать компонент игры в гляделки и анимации загрузки
    const gameShowing = ref(false);
    // была ли загружена моя игра
    const gameLoadedMe = ref(false);
    // была ли загружена игра другого человека
    const gameLoadedOther = ref(false);

    const gameLogicRef: Ref<GameLogicInterface | null> = ref(null);

    /// из GamesInterface
    const receivedGameOffer = () => {
      offerShowing.value = true;
    };

    const receivedOfferAnswer = (wants: boolean) => {
      if (wants) {
        gameShowing.value = true;
      } else {
        offerDeclinedShowing.value = true;
        setTimeout(() => {
          offerDeclinedShowing.value = false;
        }, OFFER_DECLINED_TIMEOUT);
      }
    };

    const receivedGameLoaded = async () => {
      gameLoadedOther.value = true;
      await tryStartGame();
    };

    const receivedGameStarted = () => {
      gameLogicRef.value?.startGame();
    };

    const receivedOtherBlinked = (v: { myCount: Number }) => {
      gameLogicRef.value?.receivedOtherBlinked(v);
    };

    const resetState = async () => {
      await gameLogicRef.value?.stopGame();
      offerShowing.value = false;
      offerDeclinedShowing.value = false;
      gameShowing.value = false;
      gameLoadedMe.value = false;
      gameLoadedOther.value = false;
    };

    // внутренняя логика

    const offerAnswered = (wants: boolean) => {
      context.emit("offer-answered", wants);
      offerShowing.value = false;
      if (wants) {
        gameShowing.value = true;
      }
    };

    const onGameLoadedMe = async () => {
      gameLoadedMe.value = true;
      context.emit("game-loaded");
      await tryStartGame();
    };

    const tryStartGame = async () => {
      if (gameLoadedMe.value && gameLoadedOther.value) {
        gameLogicRef.value?.showGame();
      }
    };

    const onGameEnded = async () => {
      await resetState();
      context.emit("game-ended");
    };

    return {
      offerShowing,
      offerAnswered,
      offerDeclinedShowing,
      receivedGameOffer,
      resetState,
      receivedOfferAnswer,
      receivedGameLoaded,
      receivedOtherBlinked,

      gameLogicRef,
      gameShowing,
      gameLoadedMe,
      gameLoadedOther,
      receivedGameStarted,
      onGameLoadedMe,
      onGameEnded,
    };
  },
});
