
import { defineComponent, ref, watch } from "vue";

import { event } from "vue-gtag";

import LoaderSimple from "./LoaderSimple.vue";

export default defineComponent({
  components: {
    LoaderSimple,
  },
  emits: [
    /** Была нажата кнопка "Play Stare Contest" */
    "play-sc-clicked",
  ],
  props: {
    /** Ждет ли ответа на запрос поиграть */
    startGameBtnWaiting: {
      type: Boolean,
      default: false,
    },

    /** Можно ли нажать на кнопку поиграть */
    startGameBtnDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const toggleShow = ref(false);

    const playStareContestClicked = () => {
      event("playStareContestBtnClicked_mobile");

      context.emit("play-sc-clicked");
      toggleShow.value = false;
    };

    watch(
      () => props.startGameBtnWaiting,
      () => {
        toggleShow.value = false;
      }
    );

    watch(
      () => props.startGameBtnDisabled,
      () => {
        toggleShow.value = false;
      }
    );

    return {
      toggleShow,
      playStareContestClicked,
    };
  },
});
