
import { defineComponent, onMounted, ref, Ref } from "vue";

export default defineComponent({
  setup() {
    const dotsSpan: Ref<HTMLSpanElement | null> = ref(null);

    const animateDots = () => {
      if (!dotsSpan.value) {
        return;
      }

      let len = dotsSpan.value.innerText.length;

      switch (len) {
        case 0: {
          dotsSpan.value.innerText = ".";
          break;
        }
        case 1: {
          dotsSpan.value.innerText = "..";
          break;
        }
        case 2: {
          dotsSpan.value.innerText = "...";
          break;
        }
        case 3:
        default: {
          dotsSpan.value.innerText = "";
          break;
        }
      }

      setTimeout(() => {
        animateDots();
      }, 500);
    };

    onMounted(() => {
      animateDots();
    });

    return {
      dotsSpan,
    };
  },
});
