
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    /**
     * Класс цвета, который нужно применить к loader.
     * Должен быть один из классов из локального стиля.
     */
    color: {
      type: String,
      default: "white",
    },

    /**
     * Класс размера, который нужно применить к loader.
     * Должен быть один из классов из локального стиля.
     */
    size: {
      type: String,
      default: "md",
    },
  },
});
