
import {
  defineComponent,
  defineAsyncComponent,
  Ref,
  ref,
  onMounted,
} from "vue";
import { event } from "vue-gtag";
import { useStore } from "vuex";

import ErrorService from "./ErrorService";
import { createGameLogic } from "./plugins/App";

import { BlockReason, ChatApi } from "./api/chat";

import BannerWebrtcNotSupported from "./components/BannerWebrtcNotSupported.vue";

import ChatWebcams, {
  ChatWebcamsP2PInterface,
} from "./components/ChatWebcams.vue";

const GameStaringContest = defineAsyncComponent(
  () => import("./components/GameStaringContest/GameStaringContest.vue")
);

import ControlsDesktop, {
  ControlsDesktopInterface,
} from "./components/ControlsDesktop.vue";

import ControlsMobile, {
  ControlsMobileInterface,
} from "./components/ControlsMobile.vue";

export default defineComponent({
  components: {
    BannerWebrtcNotSupported,
    ControlsDesktop,
    ControlsMobile,
    ChatWebcams,
    GameStaringContest,
  },
  setup() {
    const store = useStore();

    const webrtcSupported = ref(true);

    let rouletteStarted = ref(false); // нужно ли переподключаться к другому человеку после отключения от чата
    let chatLooking = ref(false); // идет ли поиск собеседника
    let chatStarted = ref(false); // идет ли чат с человеком

    const chatP2p: Ref<ChatWebcamsP2PInterface | null> = ref(null);
    const remoteWebrtcData: Ref<Object | null> = ref(null); // reactive data от другого peer, которую слушает чат

    const controlsDesktopRef: Ref<ControlsDesktopInterface | null> = ref(null);
    const controlsMobileRef: Ref<ControlsMobileInterface | null> = ref(null);

    const chatApi = new ChatApi({
      url: process.env.VUE_APP_CHAT_API_URL,
      onMatched: (v: {
        via: "webrtc" | "video";
        is_initiator?: boolean;
        src_url?: string;
      }) => {
        chatStarted.value = true;
        chatLooking.value = false;

        startGameBtnDisabled.value = false;

        if (v.via == "webrtc") {
          if (chatP2p.value) {
            chatP2p.value.connect(v.is_initiator ? v.is_initiator : false);
          }
          setGameUser("webrtc");
        }

        if (v.via == "video") {
          if (v.src_url) {
            chatP2p.value?.playVideo(v.src_url);
            setGameUser("video");
          } else {
            nextBtnClicked();
          }
        }
      },
      onWebrtcReceived: (remote_data: Object) => {
        remoteWebrtcData.value = remote_data;
      },
      onMessageReceived: (message) => {
        if (controlsMobileRef.value) {
          controlsMobileRef.value.gotMessage(message);
        }

        if (controlsDesktopRef.value) {
          controlsDesktopRef.value.gotMessage(message);
        }
      },
      onChatDisconnected: () => {
        if (rouletteStarted.value) {
          stopChat();
          startLookingForPartner();
        }
      },
      onGameRequested: (game: string) => {
        apiOfferReceived(game);
      },
      onGameAnswered: (wants) => {
        apiAnswerReceived(wants);
      },
      onGameOtherLoaded: () => {
        apiLoadedReceived();
      },
      onGameStarted: () => {
        apiOnGameStarted();
      },
      onGameStatusChanged: (v) => {
        apiOnUserBlinked(v);
      },
    });

    onMounted(async () => {
      event("appMounted");

      store.commit("generateClientId");

      let connectRes = await chatApi.connect(store.getters.clientId);

      if (connectRes === "error") {
        event("errorChatConnected");
        ErrorService.error("Error connecting to chat api");
      }
    });

    const onWebcamAllowed = () => {
      event("webcamAllowed");

      if (controlsMobileRef.value) {
        controlsMobileRef.value.onWebcamAllowed();
      }

      if (controlsDesktopRef.value) {
        controlsDesktopRef.value.onWebcamAllowed();
      }
    };

    const onWebcamDenied = () => {
      event("webcamDenied");

      if (controlsMobileRef.value) {
        controlsMobileRef.value.onWebcamDenied();
      }

      if (controlsDesktopRef.value) {
        controlsDesktopRef.value.onWebcamDenied();
      }
    };

    let faceInCamera = false;

    // Камера не нашла лицо в кадре
    const onFacesFound = (isFound: boolean) => {
      event("facesFound", { isFound });
      faceInCamera = isFound;

      if (controlsMobileRef.value) {
        controlsMobileRef.value.onFacesFound(isFound);
      }

      if (controlsDesktopRef.value) {
        controlsDesktopRef.value.onFacesFound(isFound);
      }
    };

    // Пользователь отказался показать лицо
    const onFaceDeclined = () => {
      event("onFaceDeclined");
      stopBtnClicked();
    };

    // Chat related APIs

    const startLookingForPartner = () => {
      if (!faceInCamera) {
        stopBtnClicked();
        return;
      }

      event("startLooking");

      chatApi.sendStatusLooking();
      chatLooking.value = true;
    };

    const sendWebrtcDataToRemote = (data: any) => {
      if (chatStarted.value) {
        chatApi.sendWebrtcData(data);
      }
    };

    // Roulette related APIs

    const stopChat = (p?: { noDisconnect?: boolean }) => {
      event("stopChat");

      chatStarted.value = false;

      startGameBtnDisabled.value = true;

      if (chatP2p.value) {
        if (!p?.noDisconnect) {
          chatP2p.value.disconnect();
          chatP2p.value.stopVideo();
        }
      }

      if (controlsMobileRef.value) {
        controlsMobileRef.value.clearMessages();
      }

      if (controlsDesktopRef.value) {
        controlsDesktopRef.value.clearMessages();
      }

      resetGameState();
    };

    const stopRoulette = () => {
      event("stopRoulette");

      rouletteStarted.value = false;
      chatLooking.value = false;
      chatApi.sendStatusStopped();
    };

    const startBtnClicked = async () => {
      event("startBtnClicked");

      rouletteStarted.value = true;
      startLookingForPartner();
    };

    const stopBtnClicked = async () => {
      event("stopBtnClicked");

      stopRoulette();
      stopChat();
    };

    const nextBtnClicked = async () => {
      event("nextBtnClicked");

      stopChat();

      startLookingForPartner();
    };

    const reportBtnClicked = async (reason: BlockReason) => {
      event("reportBtnClicked");

      await chatApi.blockUser(reason);

      stopChat();
      startLookingForPartner();
    };

    const onPartnerDisconnected = () => {
      event("partnerDisconnected");

      stopChat({ noDisconnect: false });
      chatLooking.value = false;

      if (rouletteStarted.value) {
        startLookingForPartner();
      }
    };

    const onMessageSent = (text: String) => {
      event("messageSent");

      let res = chatApi.sendMessage(text);

      if (res == "error") {
        ErrorService.error("Error sending text message");
      }
    };

    // Camera related

    const chooseCamera = (camera: "front" | "back") => {
      chatP2p.value?.chooseCamera(camera);
    };

    // Game related
    const {
      startGameBtnWaiting,
      startGameBtnDisabled,
      gamesRef,
      setGameUser,

      apiOfferReceived,
      apiAnswerReceived,
      apiLoadedReceived,
      apiOnGameStarted,
      apiOnUserBlinked,

      startStareContestBtnClicked,
      answerBtnClicked,

      resetGameState,
      onGameLoaded,
      onGameStarted,
      onUserBlinked,
      onGameEnded,
    } = createGameLogic(chatApi);

    return {
      webrtcSupported,
      rouletteStarted,
      chatLooking,
      chatStarted,
      chatP2p,
      remoteWebrtcData,
      controlsDesktopRef,
      controlsMobileRef,
      sendWebrtcDataToRemote,
      startBtnClicked,
      stopBtnClicked,
      nextBtnClicked,
      reportBtnClicked,
      onWebcamAllowed,
      onWebcamDenied,
      onFacesFound,
      onFaceDeclined,
      onPartnerDisconnected,
      onMessageSent,
      chooseCamera,

      // game related
      startGameBtnWaiting,
      startGameBtnDisabled,
      gamesRef,
      startStareContestBtnClicked,
      answerBtnClicked,
      onGameLoaded,
      onGameStarted,
      onUserBlinked,
      resetGameState,
      onGameEnded,
    };
  },
});
