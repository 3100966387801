import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "absolute top-3 right-3"
}
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = {
  key: 1,
  class: "absolute top-0 left-0 right-0"
}
const _hoisted_6 = { class: "absolute bottom-1 flex space-x-2 justify-center p-3 w-screen" }
const _hoisted_7 = {
  key: 0,
  class: "w-full"
}
const _hoisted_8 = { class: "mb-2 rounded bg-gray-100 p-2 h-72" }
const _hoisted_9 = { class: "flex space-x-2 m-auto rounded-lg bg-blue-100 p-1 w-min text-2xl text-gray-700" }
const _hoisted_10 = {
  key: 1,
  class: "p-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerWebcamDeniedMobile = _resolveComponent("BannerWebcamDeniedMobile")!
  const _component_BannerNoFaceDetected = _resolveComponent("BannerNoFaceDetected")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_DialogReport = _resolveComponent("DialogReport")!
  const _component_BannerGetApp = _resolveComponent("BannerGetApp")!
  const _component_BoxMessages = _resolveComponent("BoxMessages")!
  const _component_DialogMobileSelectGame = _resolveComponent("DialogMobileSelectGame")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.webcamDenied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BannerWebcamDeniedMobile, {
            onChooseCamera: _cache[0] || (_cache[0] = (cam) => _ctx.$emit('choose-camera', cam))
          })
        ]))
      : (_ctx.noFaceDetected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_BannerNoFaceDetected, {
              ref: "bannerNoFaceRef",
              onCounterFinished: _cache[1] || (_cache[1] = () => _ctx.$emit('face-declined'))
            }, null, 512)
          ]))
        : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      (_ctx.rouletteStarted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "rounded bg-blue-100 p-2 text-xl text-gray-700",
                "aria-label": "Report user",
                onClick: _cache[2] || (_cache[2] = () => _ctx.dialogReportRef.toggleShow())
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "flag",
                  class: "mr-1"
                })
              ]),
              _createVNode(_component_DialogReport, {
                ref: "dialogReportRef",
                "dialog-class": "top-full right-0 mt-4 mr-2",
                onReasonSelected: _cache[3] || (_cache[3] = (r) => _ctx.$emit('report-clicked', r))
              }, null, 512)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_BannerGetApp, { class: "flex flex-col items-center justify-center m-auto p-4 bg-gradient-to-r from-blue-800 to-purple-600 text-white" })
          ])),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.rouletteStarted)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _withDirectives(_createElementVNode("div", _hoisted_8, [
                _createVNode(_component_BoxMessages, {
                  ref: "boxMessagesRef",
                  onMessageSent: _ctx.onMessageSent
                }, null, 8, ["onMessageSent"])
              ], 512), [
                [_vShow, _ctx.showMessages]
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", {
                  class: "px-2 py-1",
                  "aria-label": "Stop",
                  onClick: _cache[4] || (_cache[4] = () => _ctx.$emit('stop-clicked'))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "stop" })
                ]),
                _createElementVNode("button", {
                  class: "px-2 py-1",
                  "aria-label": "Next",
                  onClick: _cache[5] || (_cache[5] = () => _ctx.$emit('next-clicked'))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "arrow-right" })
                ]),
                _createElementVNode("button", {
                  class: "px-2 py-1",
                  "aria-label": "Toggle messages",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleShowMessages && _ctx.toggleShowMessages(...args)))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "comment" })
                ]),
                _createVNode(_component_DialogMobileSelectGame, {
                  class: "px-2 py-1",
                  startGameBtnWaiting: _ctx.startGameBtnWaiting,
                  startGameBtnDisabled: _ctx.startGameBtnDisabled,
                  onPlayScClicked: _cache[7] || (_cache[7] = () => _ctx.$emit('play-sc-clicked'))
                }, null, 8, ["startGameBtnWaiting", "startGameBtnDisabled"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "bg-blue-500 px-2 py-1 text-2xl font-semibold text-white",
                onClick: _cache[8] || (_cache[8] = () => _ctx.$emit('start-clicked'))
              }, " Start ")
            ]))
      ])
    ], 512), [
      [_vShow, !_ctx.webcamDenied && !_ctx.noFaceDetected]
    ])
  ]))
}