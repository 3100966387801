
import { defineComponent, nextTick, Ref, ref } from "vue";

import BoxMessages, { BoxMessagesInterface } from "./BoxMessages.vue";

import BannerWebcamDeniedMobile from "./BannerWebcamDeniedMobile.vue";
import BannerNoFaceDetected, {
  BannerNoFaceDetectedInterface,
} from "./BannerNoFaceDetected.vue";
import BannerGetApp from "./BannerGetApp.vue";

import DialogReport, { DialogReportInterface } from "./DialogReport.vue";

import DialogMobileSelectGame from "./DialogMobileSelectGame.vue";

import { noFaceLogic } from "../plugins/ControlsShared";

export interface ControlsMobileInterface {
  // eslint-disable-next-line no-unused-vars
  gotMessage(text: String): void;
  clearMessages(): void;

  onWebcamDenied(): void;
  onWebcamAllowed(): void;
  // eslint-disable-next-line no-unused-vars
  onFacesFound(isFound: boolean): void;
}

export default defineComponent({
  components: {
    BoxMessages,
    BannerWebcamDeniedMobile,
    BannerNoFaceDetected,
    BannerGetApp,
    DialogReport,
    DialogMobileSelectGame,
  },
  emits: [
    "start-clicked",
    "stop-clicked",
    "next-clicked",
    "report-clicked",
    "message-sent",

    /**
     * Emitted каждый раз когда была выбрана камера
     */
    "choose-camera",

    /**
     * Emitted если пользователь отказался
     * показать лицо в течение нескольких секунд
     */
    "face-declined",

    /** Emitted если пользователь нажал на кнопку "Play Stare Contest" */
    "play-sc-clicked",
  ],
  props: {
    /** Была ли начата чат рулетка */
    rouletteStarted: {
      type: Boolean,
      default: false,
    },

    /** Текст нового сообщения */
    newMessageText: {
      type: String,
      default: null,
    },

    /** Показывать ли анимацию что отправлено сообщение поиграть */
    startGameBtnWaiting: {
      type: Boolean,
      default: false,
    },

    /** Можно ли нажать на кнопку поиграть */
    startGameBtnDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const showMessages: Ref<boolean> = ref(false);

    const toggleShowMessages = () => {
      showMessages.value = !showMessages.value;
    };

    const boxMessagesRef: Ref<BoxMessagesInterface | null> = ref(null);
    const dialogReportRef: Ref<DialogReportInterface | null> = ref(null);

    const gotMessage = (message: string) => {
      if (message == null) {
        return;
      }

      showMessages.value = true;
      nextTick(() => {
        if (boxMessagesRef.value) {
          boxMessagesRef.value.gotMessage(message);
        }
      });
    };

    const onMessageSent = (text: String) => {
      context.emit("message-sent", text);
    };

    const clearMessages = () => {
      if (boxMessagesRef.value) {
        boxMessagesRef.value.clearMessages();
      }

      if (dialogReportRef.value) {
        dialogReportRef.value.clear();
      }

      showMessages.value = false;
    };

    const webcamDenied = ref(true);

    const onWebcamAllowed = () => {
      webcamDenied.value = false;
    };

    const onWebcamDenied = () => {
      webcamDenied.value = true;
    };

    const bannerNoFaceRef: Ref<BannerNoFaceDetectedInterface | null> =
      ref(null);
    const { noFaceDetected, onFacesFound } = noFaceLogic(bannerNoFaceRef);

    return {
      gotMessage,
      onMessageSent,
      clearMessages,
      webcamDenied,
      onWebcamAllowed,
      onWebcamDenied,
      bannerNoFaceRef,
      noFaceDetected,
      onFacesFound,
      boxMessagesRef,
      dialogReportRef,
      showMessages,
      toggleShowMessages,
    };
  },
});
