
import { defineComponent } from "vue";

export default defineComponent({
  events: [
    /**
     * Пользователь ответил хочет ли поиграть в игру
     * Параметры:
     * 1 - wants { boolean }. true, если хочет; false - если нет
     */
    "answered",
  ],
  setup() {
    return {};
  },
});
