import { ChatApi } from "@/api/chat";
import { ref, Ref } from "vue";

/** Минимальное количество миллисекунд, после которого видео ответит что не хочет играть */
const VIDEO_CLICKOFF_MIN = 1500;
/** Предел случайного количества миллисекунд */
const VIDEO_CLICKOFF_RANGE = 1500;

export interface GamesInterface {
  /** Спросить пользователя хочет ли он поиграть в игру */
  receivedGameOffer(): void;

  /** Другой пользователь ответил на предложение поиграть в игру */
  receivedOfferAnswer(wants: boolean): void;

  /** Сбросить состояние игры, все состояния, все popup'ы, ... */
  resetState(): Promise<void>;

  /** Другой пользователь сказал, что у него тоже загрузилась игра */
  receivedGameLoaded(): void;

  /** Другой пользователь нажал кнопку начать */
  receivedGameStarted(): void;

  /** Другой пользователь моргнул */
  // eslint-disable-next-line no-unused-vars
  receivedOtherBlinked(v: { myCount: number }): void;
}

/** Логика, относящаяся к играм в чате */
export function createGameLogic(api: ChatApi): {
  startGameBtnWaiting: Ref<boolean>;
  startGameBtnDisabled: Ref<boolean>;

  gamesRef: Ref<GamesInterface | null>;
  setGameUser: (user: "video" | "webrtc") => void;

  startStareContestBtnClicked: () => void;
  answerBtnClicked: (wants: boolean) => void;
  apiOfferReceived: (game: string) => void;
  apiAnswerReceived: (wants: boolean) => void;
  apiLoadedReceived: () => void;
  apiOnGameStarted: () => void;
  apiOnUserBlinked: (v: { myCount: number }) => void;

  resetGameState: () => void;
  onGameLoaded: () => void;
  onGameStarted: () => void;
  onUserBlinked: (v: { myCount: number }) => void;
  onGameEnded: () => void;
} {
  const gamesRef: Ref<GamesInterface | null> = ref(null);

  // Ждет ли другой пользователь ответа на поиграть в игру.
  // Если true, то будет показываться loader что приглашение поиграть отправлено
  const startGameBtnWaiting = ref(false);

  // Можно ли нажать кнопку поиграть
  const startGameBtnDisabled = ref(true);

  let isVideo = false;
  let videoTimeout: number | null = null;

  const setGameUser = (user: "video" | "webrtc") => {
    if (user == "video") {
      isVideo = true;
    } else {
      isVideo = false;
    }
  };

  // Была нажата кнопка запросить игру
  const startStareContestBtnClicked = () => {
    console.log("start game btn clicked");

    if (isVideo) {
      videoTimeout = setTimeout(
        () => apiAnswerReceived(false),
        Math.random() * VIDEO_CLICKOFF_RANGE + VIDEO_CLICKOFF_MIN
      );
    } else {
      api.sendGameRequest("stare-contest");
    }

    startGameBtnWaiting.value = true;
    startGameBtnDisabled.value = true;
  };

  // Было получено приглашение на запрос игры
  const apiOfferReceived = (game: string) => {
    startGameBtnWaiting.value = false;
    startGameBtnDisabled.value = true;

    gamesRef.value?.receivedGameOffer();
  };

  // Была нажата кнопка ответа на запрос игры (хочет/не хочет играть)
  const answerBtnClicked = (wants: boolean) => {
    api.answerGameRequest(wants);
    if (!wants) {
      startGameBtnDisabled.value = false;
    }
  };

  // Был получен ответ на запрос игры (хочет/не хочет играть)
  const apiAnswerReceived = (wants: boolean) => {
    startGameBtnWaiting.value = false;

    if (wants) {
      startGameBtnDisabled.value = true;
    } else {
      startGameBtnDisabled.value = false;
    }

    gamesRef.value?.receivedOfferAnswer(wants);
  };

  // Игра была загружена у меня
  const onGameLoaded = () => {
    api.sendGameLoaded();
  };

  // Был получен ответ что игра загружена у другого пользователя
  const apiLoadedReceived = () => {
    gamesRef.value?.receivedGameLoaded();
  };

  // Этот пользователь нажал кнопку начать
  const onGameStarted = () => {
    console.log("onGameStarted");
    api.sendGameStarted();
  };

  // Другой пользователь нажал кнопку начать
  const apiOnGameStarted = () => {
    gamesRef.value?.receivedGameStarted();
  };

  // Сбросить статус игры (например, когда нажали далее или остановить)
  const resetGameState = () => {
    startGameBtnWaiting.value = false;
    startGameBtnDisabled.value = true;

    if (videoTimeout) {
      clearTimeout(videoTimeout);
    }
    videoTimeout = null;

    gamesRef.value?.resetState();
  };

  // Этот пользователь моргнул. v - то, что было передано в event
  const onUserBlinked = (v: { myCount: number }) => {
    api.sendGameStatusChange(v);
  };

  // Другой пользователь моргнул
  const apiOnUserBlinked = (v: { myCount: number }) => {
    console.log("apiOnUserBlinked", v, "game ref: ", gamesRef);
    gamesRef.value?.receivedOtherBlinked(v);
  };

  const onGameEnded = () => {
    startGameBtnWaiting.value = false;
    startGameBtnDisabled.value = false;
  };

  return {
    startGameBtnWaiting,
    startGameBtnDisabled,

    setGameUser,

    apiOfferReceived,
    answerBtnClicked,
    apiAnswerReceived,
    apiLoadedReceived,
    apiOnGameStarted,
    apiOnUserBlinked,
    startStareContestBtnClicked,
    resetGameState,

    gamesRef,
    onGameLoaded,
    onGameStarted,
    onUserBlinked,
    onGameEnded,
  };
}
