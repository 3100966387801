
import { defineComponent, onMounted, ref, Ref } from "vue";

import { event } from "vue-gtag";

import BannerWebcamDeniedDesktop from "./BannerWebcamDeniedDesktop.vue";
import BannerNoFaceDetected, {
  BannerNoFaceDetectedInterface,
} from "./BannerNoFaceDetected.vue";
import BannerGetApp from "./BannerGetApp.vue";

import LoaderSimple from "./LoaderSimple.vue";

import BoxMessages, { BoxMessagesInterface } from "./BoxMessages.vue";
import DialogReport, { DialogReportInterface } from "./DialogReport.vue";

import { noFaceLogic } from "../plugins/ControlsShared";

export interface ControlsDesktopInterface {
  // eslint-disable-next-line no-unused-vars
  gotMessage(text: String): void;
  clearMessages(): void;

  onWebcamDenied(): void;
  onWebcamAllowed(): void;
  // eslint-disable-next-line no-unused-vars
  onFacesFound(isFound: boolean): void;
}

export default defineComponent({
  components: {
    BannerWebcamDeniedDesktop,
    BannerNoFaceDetected,
    BannerGetApp,
    LoaderSimple,
    BoxMessages,
    DialogReport,
  },
  props: {
    /** Была ли начата чат рулетка */
    rouletteStarted: {
      type: Boolean,
      default: false,
    },

    /** Показывать ли анимацию что отправлено сообщение поиграть */
    startGameBtnWaiting: {
      type: Boolean,
      default: false,
    },

    /** Можно ли нажать на кнопку поиграть */
    startGameBtnDisabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    /**
     * Emitted, когда была нажата кнопка Start
     */
    "start-clicked",

    /**
     * Emitted, когда была нажата кнопка Stop
     */
    "stop-clicked",

    /**
     * Emitted, когда была нажата кнопка Next
     */
    "next-clicked",

    /**
     * Emitted, когда была нажата кнопка Report
     */
    "report-clicked",

    /**
     * Emitted, когда сообщение было отправлено
     * Параметры:
     * - text {String}
     */
    "message-sent",

    /**
     * Пользователь отказался показать лицо
     */
    "face-declined",

    /**
     * Emitted, когда камера была выбрана
     */
    "choose-camera",

    /** Была нажата кнопка "Play Stare Contest" */
    "play-sc-clicked",
  ],
  setup(props, context) {
    const boxMessagesRef: Ref<BoxMessagesInterface | null> = ref(null);
    const dialogReportRef: Ref<DialogReportInterface | null> = ref(null);

    const gotMessage = (newText: string) => {
      if (newText == null) {
        return;
      }

      if (boxMessagesRef.value) {
        boxMessagesRef.value.gotMessage(newText);
      }
    };

    const clearMessages = () => {
      if (boxMessagesRef.value) {
        boxMessagesRef.value.clearMessages();
      }

      if (dialogReportRef.value) {
        dialogReportRef.value.clear();
      }
    };

    const onMessageSent = (text: String) => {
      context.emit("message-sent", text);
    };

    const webcamDenied = ref(true);

    const onWebcamAllowed = () => {
      webcamDenied.value = false;
    };

    const onWebcamDenied = () => {
      webcamDenied.value = true;
    };

    const bannerNoFaceRef: Ref<BannerNoFaceDetectedInterface | null> =
      ref(null);
    const { noFaceDetected, onFacesFound } = noFaceLogic(bannerNoFaceRef);

    onMounted(() => {
      context.emit("choose-camera", "front");
    });

    const playStareContestClicked = () => {
      event("playStareContestBtnClicked_desktop");

      context.emit("play-sc-clicked");
    };

    return {
      onMessageSent,
      gotMessage,
      boxMessagesRef,
      dialogReportRef,
      clearMessages,
      onWebcamDenied,
      onWebcamAllowed,
      webcamDenied,
      bannerNoFaceRef,
      noFaceDetected,
      onFacesFound,

      playStareContestClicked,
    };
  },
});
