
import { defineComponent, ref, Ref } from "vue";

import * as tf from "@tensorflow/tfjs-core";
import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";
import * as blazeface from "@tensorflow-models/blazeface";

import ErrorService from "../ErrorService";

const placeholder = require("assets/images/video-placeholder.png");

const MAX_WEBCAM_ACCESS_ATTEMPTS = 3;

const FACE_CHECK_TIMEOUT_MS = 2000;
const FACE_CHECK_MIN_PROBABILITY = 0.8;

export interface VideoLocalWebcamInterface {
  /**
   * Получить media stream для пользователя
   */
  // eslint-disable-next-line no-unused-vars
  getMediaStream(facingUser: Boolean): void;
}

export default defineComponent({
  emits: [
    /** Emitted каждый раз когда video stream была создана.
     *   Параметры:
     *   1 - stream {MediaStream} - stream, которая была создана.
     *     если null, camera permission не было дано
     */
    "stream-created",

    /**
     * Emitted каждый раз когда лица нет в кадре
     * Параметры:
     * 1 - isFound {boolean} - были ли найдены лица в MediaStream
     */
    "faces-found",
  ],
  setup(props, context) {
    const videoRef: Ref<HTMLVideoElement | null> = ref(null);
    const isFrontCam = ref(false);

    let model: null | blazeface.BlazeFaceModel = null;

    const initBlazeFace = async () => {
      await tfjsWasm.setWasmPaths("/tf/wasm-out-3.13/");
      await tf.setBackend("wasm");

      model = await blazeface.load({ modelUrl: "/tf/blazeface/model.json" });
    };

    const getFacePredictions = async () => {
      if (!videoRef.value) {
        ErrorService.error("cannot load video element for BlazeFace");
        context.emit("faces-found", false);
        return;
      }

      if (!model) {
        await initBlazeFace();
        ErrorService.error("Cannot get model");

        if (!model) {
          ErrorService.error("Cannot get model after retry");
          context.emit("faces-found", false);
          context.emit("stream-created", null);
          videoRef.value.srcObject = null;
        }
        return;
      }

      const predictions = await model.estimateFaces(videoRef.value, false);

      if (predictions.length <= 0) {
        context.emit("faces-found", false);
        return;
      }

      const probability = predictions[0].probability;

      if (!probability) {
        context.emit("faces-found", false);
        return;
      }

      if (typeof probability == "number") {
        if (probability < FACE_CHECK_MIN_PROBABILITY) {
          context.emit("faces-found", false);
        } else {
          context.emit("faces-found", true);
        }
      } else {
        let prob_arr: any = probability;
        if (prob_arr[0]) {
          if (prob_arr[0] >= FACE_CHECK_MIN_PROBABILITY) {
            context.emit("faces-found", true);
          } else {
            context.emit("faces-found", false);
          }
        } else {
          context.emit("faces-found", false);
        }
      }
    };

    const getFacePredictionsTimeout = async () => {
      await getFacePredictions();

      setTimeout(getFacePredictionsTimeout, FACE_CHECK_TIMEOUT_MS);
    };

    const getMediaStream = async (facingUser: boolean) => {
      let mediaStream: MediaStream | null = null;
      isFrontCam.value = facingUser;

      for (let f = 0; f < MAX_WEBCAM_ACCESS_ATTEMPTS; f++) {
        try {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            video: {
              width: { min: 426, ideal: 640, max: 1280 },
              height: { min: 240, ideal: 480, max: 720 },
              frameRate: { min: 5, ideal: 20, max: 30 },
              facingMode: facingUser ? "user" : "environment",
            },
            audio: {
              echoCancellation: true,
            },
          });
          break;
        } catch (e) {
          context.emit("stream-created", null);
          mediaStream = null;
          ErrorService.error("Video stream error: ", e);

          await new Promise((resolve) =>
            setTimeout(() => {
              resolve(0);
            }, 1000)
          );

          if (f === MAX_WEBCAM_ACCESS_ATTEMPTS - 1) {
            return;
          }
        }
      }

      if (!mediaStream) {
        context.emit("stream-created", null);
        return;
      }

      const video = videoRef.value;

      if (video) {
        await initBlazeFace();

        video.srcObject = mediaStream;

        context.emit("stream-created", mediaStream.clone());

        video.addEventListener("loadedmetadata", async () => {
          const vid = videoRef.value;

          if (vid) {
            await vid.play();
            await getFacePredictionsTimeout();
          }
        });
      }
    };

    return {
      videoRef,
      placeholder,
      isFrontCam,
      getMediaStream,
    };
  },
});
